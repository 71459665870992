import { createStaticRanges } from "react-date-range";
import { startOfISOWeek, endOfISOWeek, subDays, isSameDay } from "date-fns";

export const customDateRanges = createStaticRanges([
  {
    label: 'Today',
    range: () => ({
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }),
    isSelected: (range) => {
      if (range.startDate && range.endDate) {
        return (
          isSameDay(range.startDate, new Date()) &&
          isSameDay(range.endDate, new Date())
        );
      }
      return false;
    }
  },
  {
    label: 'Yesterday',
    range: () => ({
      startDate: subDays(new Date(), 1),
      endDate: subDays(new Date(), 1),
      key: 'selection'
    }),
    isSelected: (range) => {
      if (range.startDate && range.endDate) {
        return (
          isSameDay(range.startDate, subDays(new Date(), 1)) &&
          isSameDay(range.endDate, subDays(new Date(), 1))
        );
      }
      return false;
    }
  },
  {
    label: 'This week',
    range: () => ({
      startDate: startOfISOWeek(new Date()),
      endDate: endOfISOWeek(new Date()),
      key: 'selection'
    }),
    isSelected: (range) => {
      if (range.startDate && range.endDate) {
        return (
          isSameDay(range?.startDate, startOfISOWeek(new Date())) &&
          isSameDay(range?.endDate, endOfISOWeek(new Date()))
        )
      }
      return false;
    }
  },
  {
    label: 'Last week',
    range: () => ({
      startDate: startOfISOWeek(subDays(new Date(), 7)),
      endDate: endOfISOWeek(subDays(new Date(), 7)),
      key: 'selection'
    }),
    isSelected: (range) => {
      if (range.startDate && range.endDate) {
        return (
          isSameDay(range.startDate, startOfISOWeek(subDays(new Date(), 7))) &&
          isSameDay(range.endDate, endOfISOWeek(subDays(new Date(), 7)))
        )
      }
      return false;
    }
  },
  {
    label: 'This month',
    range: () => ({
      startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      key: 'selection'
    }),
    isSelected: (range) => {
      if (range.startDate && range.endDate) {
        return (
          isSameDay(range.startDate, new Date(new Date().getFullYear(), new Date().getMonth(), 1)) &&
          isSameDay(range.endDate, new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0))
        );
      }
      return false;
    }
  },
  {
    label: 'Last month',
    range: () => ({
      startDate: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
      endDate: new Date(new Date().getFullYear(), new Date().getMonth(), 0),
      key: 'selection'
    }),
    isSelected: (range) => {
      if (range.startDate && range.endDate) {
        return (
          isSameDay(range.startDate, new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)) &&
          isSameDay(range.endDate, new Date(new Date().getFullYear(), new Date().getMonth(), 0))
        );
      }
      return false;
    }
  },
  {
    label: 'This year',
    range: () => ({
      startDate: new Date(new Date().getFullYear(), 0, 1),
      endDate: new Date(new Date().getFullYear(), 11, 31),
      key: 'selection'
    }),
    isSelected: (range) => {
      if (range.startDate && range.endDate) {
        return (
          isSameDay(range.startDate, new Date(new Date().getFullYear(), 0, 1)) &&
          isSameDay(range.endDate, new Date(new Date().getFullYear(), 11, 31))
        );
      }
      return false;
    }
  },
  {
    label: 'Last year',
    range: () => ({
      startDate: new Date(new Date().getFullYear() - 1, 0, 1),
      endDate: new Date(new Date().getFullYear() - 1, 11, 31),
      key: 'selection'
    }),
    isSelected: (range) => {
      if (range.startDate && range.endDate) {
        return (
          isSameDay(range.startDate, new Date(new Date().getFullYear() - 1, 0, 1)) &&
          isSameDay(range.endDate, new Date(new Date().getFullYear() - 1, 11, 31))
        );
      }
      return false;
    }
  },

]);