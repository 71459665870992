import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { DateRangePicker } from "react-date-range";
import { customDateRanges } from "./custom-date-ranges.js";

/**
 * WrappedDateRangePicker component that wraps the DateRangePicker from react-date-range to add an apply button.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.selectedDateRange - The currently selected date range.
 * @param {Function} props.setSelectedDateRange - Callback function to set the selected date range.
 * @param {Function} props.closePicker - Callback function to close the date range picker.
 *
 * @returns {JSX.Element} The WrappedDateRangePicker component.
 */
export const WrappedDateRangePicker = ({
  selectedDateRange,
  setSelectedDateRange,
  closePicker
}) => {
  const [dateRange, setDateRange] = useState(selectedDateRange);

  return (
    <div className="flex flex-col bg-white z-50 border border-gray-200 shadow-lg rounded-lg overflow-clip">
      <DateRangePicker
        onChange={(item) => setDateRange([item.selection])}
        showSelectionPreview={true}
        showDateDisplay={false}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={dateRange}
        direction="horizontal"
        weekStartsOn={1}
        staticRanges={customDateRanges}
        color="#EEF2FF"
        rangeColors={["rgb(17, 24, 39)"]}
      />
      <div
        className="flex justify-end p-4"
        style={{
          borderRight: "solid 1px #eff2f7",
          borderTop: "solid 1px #eff2f7",
          width: "226px",
        }}
      >
        <Button
          onClick={closePicker}
          className="mr-4 text-sm relative inline-flex cursor-pointer items-center justify-center rounded font-medium focus:outline-none focus-visible:outline-none disabled:cursor-default focus-visible:ui-ring-primary border border-neutral-300 bg-white text-neutral-900 hover:border-neutral-300 hover:bg-neutral-100 active:bg-neutral-200 disabled:border-neutral-300 disabled:bg-neutral-200 disabled:text-neutral-500 px-3 py-1">
          Cancel
        </Button>
        <Button
          onClick={() => {
            setSelectedDateRange(dateRange);
          }}
          className="text-sm relative inline-flex cursor-pointer items-center justify-center rounded font-medium focus:outline-none focus-visible:outline-none disabled:cursor-default bg-[#3B37F6] hover:bg-[#322FD1] focus-visible:bg-[#3B37F6] active:bg-[#5E5BF8] focus-visible:ui-ring-primary text-white disabled:bg-neutral-200 disabled:text-neutral-500 px-3 py-1">
          Apply
        </Button>
      </div>
    </div>
  );
};
