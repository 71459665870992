import "@fortawesome/fontawesome-free/css/all.css";
import {
  differenceInCalendarDays,
  endOfDay,
  startOfDay,
  subDays,
  parse,
  format
} from "date-fns";
// import { format } from "date-fns-tz;
import React, { useEffect, useState } from "react";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { ArrowContainer, Popover } from "react-tiny-popover";
import { PageLinks } from "./PageLinks.jsx";
import TableauEmbed from "./TableauEmbed.jsx";
import { WrappedDateRangePicker } from "./WrappedDateRangePicker.js";

export const PAGES = {
  "ondemand-overview": {
    url: process.env.REACT_APP_URL_1,
    name: "OnDemand overview",
    slug: "ondemand-overview",
    height: "1900px",
  },
  "ondemand-details": {
    url: process.env.REACT_APP_URL_2,
    name: "OnDemand details",
    slug: "ondemand-details",
    height: "2600px",
  },
  "hq-usage": {
    url: process.env.REACT_APP_URL_3,
    name: "HQ Usage",
    slug: "hq-usage",
    height: "2200px",
  },
};

const dateFormatter = new Intl.DateTimeFormat(navigator.language, {
  year: "numeric",
  month: "long",
  day: "numeric",
});

const App = () => {
  const url = new URL(window.location.toString());
  const start = url.searchParams.get("start");
  const end = url.searchParams.get("end");

  let startMoment;
  if (start) {
    startMoment = parse(start, "yyyy-MM-dd", new Date());
  }
  if (!(startMoment instanceof Date) || isNaN(startMoment)) {
    startMoment = startOfDay(subDays(new Date(), 27));
  }

  let endMoment;
  if (end) {
    endMoment = parse(end, "yyyy-MM-dd", new Date());
  }
  if (!(endMoment instanceof Date) || isNaN(endMoment)) {
    endMoment = endOfDay(new Date());
  }

  // The actual date range that is applied
  const [appliedDateRange, setAppliedDateRange] = useState([
    {
      startDate: startMoment,
      endDate: endMoment,
      key: "selection",
    },
  ]);

  const applyDateRange = (dateRange) => {
    setAppliedDateRange(dateRange);
  };

  const [selectedPage, setSelectedPage] = useState();

  const handleLinkClick = (link) => {
    // Remove local storage scroll position
    localStorage.clear("scrollPos");

    // Set the selected page
    setSelectedPage(PAGES[link]);

    const url = new URL(window.location.toString());
    url.searchParams.set(
      "start",
      format(appliedDateRange[0].startDate, "yyyy-MM-dd")
    );
    url.searchParams.set(
      "end",
      format(appliedDateRange[0].endDate, "yyyy-MM-dd")
    );
    url.pathname = PAGES[link].slug;

    window.history.pushState({}, "", url);
  };

  useEffect(() => {
    const pathname = window.location.pathname.substring(1);
    if (!pathname) {
      window.history.replaceState({}, "", PAGES["ondemand-overview"].slug);
      setSelectedPage(PAGES["ondemand-overview"]);
    } else {
      const foundPage = PAGES[pathname];
      if (foundPage) {
        setSelectedPage(foundPage);
      } else {
        setSelectedPage(PAGES["ondemand-overview"]);

        const url = new URL(window.location.toString());
        url.searchParams.set(
          "start",
          format(appliedDateRange[0].startDate, "yyyy-MM-dd")
        );
        url.searchParams.set(
          "end",
          format(appliedDateRange[0].endDate, "yyyy-MM-dd")
        );
        url.pathname = PAGES["ondemand-overview"].slug;

        window.history.replaceState({}, "", url);
      }
    }

    window.addEventListener("popstate", (e) => {
      const url = new URL(e.target.location.toString());
      const start = url.searchParams.get("start");
      const end = url.searchParams.get("end");
      setAppliedDateRange([
        {
          startDate: startOfDay(parse(start, "yyyy-MM-dd", new Date())),
          endDate: endOfDay(parse(end, "yyyy-MM-dd", new Date())),
          key: "selection",
        },
      ]);

      setSelectedPage(PAGES[url.pathname.substring(1)]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNavigate = () => {
    window.location.href = "https://admin.desana.io";
  };

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const numberOfDays =
    differenceInCalendarDays(
      appliedDateRange[0].endDate,
      appliedDateRange[0].startDate
    ) + 1;

  useEffect(() => {
    const url = new URL(window.location.toString());
    url.searchParams.set(
      "start",
      format(appliedDateRange[0].startDate, "yyyy-MM-dd")
    );
    url.searchParams.set(
      "end",
      format(appliedDateRange[0].endDate, "yyyy-MM-dd")
    );
    window.history.pushState({}, "", url);
  }, [appliedDateRange]);

  return (
    <div>
      <nav className="sticky top-0 z-50 flex h-20 w-full min-w-[1280px] items-center justify-between border-b border-b-neutral-200 bg-white px-10 py-5">
        {/* Logo and Links on the left */}
        <div className="flex items-center">
          <img
            src="/logo.svg"
            alt="Logo"
            style={{ height: "40px", marginRight: "10px" }}
          />
          <Button
            variant="outline-secondary"
            style={{ marginLeft: "10px", marginRight: "10px" }}
            onClick={handleNavigate}
          >
            <i
              className="fa fa-arrow-left"
              aria-hidden="true"
              style={{ color: "#000000", marginRight: "5px" }}
            ></i>
            Back to main dashboard
          </Button>
        </div>

        {/* Page links in the middle */}
        <div className="flex gap-4">
          <PageLinks
            selectedPage={selectedPage}
            handleLinkClick={handleLinkClick}
            startDate={appliedDateRange[0].startDate}
            endDate={appliedDateRange[0].endDate}
          />
        </div>

        {/* Date range picker and badge on the far right */}
        <div className="relative">
          <div style={{ display: "flex", alignItems: "center" }}>
            <Popover
              isOpen={isPopoverOpen}
              positions={["bottom", "left"]} // preferred positions by priority
              clickOutsideCapture={true}
              onClickOutside={() => setIsPopoverOpen(false)}
              content={({ position, childRect, popoverRect }) => (
                <ArrowContainer
                  position={position}
                  childRect={childRect}
                  popoverRect={popoverRect}
                  arrowColor={"#e5e7eb"}
                  arrowSize={10}
                  arrowStyle={{ opacity: 0.7 }}
                  className="popover-arrow-container"
                  arrowClassName="popover-arrow"
                >
                  <WrappedDateRangePicker
                    selectedDateRange={appliedDateRange}
                    setSelectedDateRange={(dateRange) => {
                      applyDateRange(dateRange);
                      setIsPopoverOpen(false);
                    }}
                    closePicker={() => setIsPopoverOpen(false)}
                  />
                </ArrowContainer>
              )}
            >
              <div
                id="reportrange"
                onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                className="border border-gray-300 cursor-pointer px-2 py-1 space-x-2 rounded-md"
              >
                <i className="fa fa-calendar" />
                &nbsp;
                <span>
                  {dateFormatter.format(appliedDateRange[0].startDate)} -{" "}
                  {dateFormatter.format(appliedDateRange[0].endDate)}
                </span>
                <i className="fa fa-caret-down" />
              </div>
            </Popover>

            <div style={{ marginLeft: "10px" }}>
              <Badge bg="light" text="dark">
                {numberOfDays} {numberOfDays === 1 ? "day" : "days"}
              </Badge>
            </div>
          </div>
        </div>
      </nav>
      {/* <p style={{ marginTop: "20px" }}>
        Start:
        {appliedDateRange[0].startDate.toISOString()}&nbsp;<br />
        {format(appliedDateRange[0].startDate, "yyyy-MM-dd", { timeZone: dateFormatter.resolvedOptions().timeZone })}&nbsp;
      </p>
      <p style={{ marginTop: "20px" }}>
        End:
        {appliedDateRange[0].endDate.toISOString()}&nbsp;<br />
        {format(appliedDateRange[0].endDate, "yyyy-MM-dd")}&nbsp;
      </p> */}
      {selectedPage && (
        <div className="h-full w-full min-w-[1280px] pb-8">
          <TableauEmbed
            className="mx-10 max-w-[1400px] min-[1544px]:m-auto"
            selectedLink={selectedPage.url}
            height={selectedPage.height}
            startDate={appliedDateRange[0].startDate}
            endDate={appliedDateRange[0].endDate}
            handleLinkClick={handleLinkClick}
          />
        </div>
      )}
    </div>
  );
};

export default App;
